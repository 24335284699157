import { ChipProps } from '@mui/material';
import {
  CampaignTypes,
  DealTypes,
  DiscountTypes,
  NegotiationTypes,
  SalesPlanningTypes,
  UserTypes,
} from 'api';
import { BrandStatusEnum } from 'api/types/Brands';

const getDealStatusColor = (
  status: DealTypes.StatusEnum,
): ChipProps['color'] => {
  switch (status) {
    case DealTypes.StatusEnum.Scheduled:
      return 'default';
    case DealTypes.StatusEnum.Finished:
      return 'success';
    case DealTypes.StatusEnum.Posted:
      return 'info';
    case DealTypes.StatusEnum.Pending:
      return 'primary';
    case DealTypes.StatusEnum.Waitlist:
    case DealTypes.StatusEnum.OnRadar:
      return 'warning';
    case DealTypes.StatusEnum.Missing:
      return 'error';
    default:
      return 'default';
  }
};

const getNegotiationStatusColor = (
  status: NegotiationTypes.StatusEnum,
): ChipProps['color'] => {
  switch (status) {
    case NegotiationTypes.StatusEnum.approved:
      return 'success';
    case NegotiationTypes.StatusEnum.contacted:
      return 'info';
    case NegotiationTypes.StatusEnum.parked:
      return 'primary';
    case NegotiationTypes.StatusEnum.renegotiatePrice:
    case NegotiationTypes.StatusEnum.pending:
    case NegotiationTypes.StatusEnum.waitingForInfo:
      return 'warning';
    case NegotiationTypes.StatusEnum.declined:
      return 'error';
    default:
      return 'default';
  }
};

const getCampaignStatusColor = (
  status: CampaignTypes.StatusEnum,
): ChipProps['color'] => {
  switch (status) {
    case CampaignTypes.StatusEnum.Ongoing:
      return 'success';
    case CampaignTypes.StatusEnum.Finished:
      return 'primary';
    default:
      return 'warning';
  }
};

const getCampaignTypeColor = (
  status: CampaignTypes.TypeEnum,
): ChipProps['color'] => {
  switch (status) {
    case CampaignTypes.TypeEnum.CRM:
      return 'primary';
    case CampaignTypes.TypeEnum.FlashSale:
      return 'secondary';
    case CampaignTypes.TypeEnum.BrandLaunch:
      return 'success';
    case CampaignTypes.TypeEnum.FestiveSeason:
      return 'warning';
    case CampaignTypes.TypeEnum.ProductLaunch:
      return 'info';
    case CampaignTypes.TypeEnum.PerformanceMarketing:
      return 'error';
    default:
      return 'default';
  }
};

const getSalesPlanningStatusColor = (
  status: SalesPlanningTypes.StatusEnum,
): ChipProps['color'] => {
  switch (status) {
    case SalesPlanningTypes.StatusEnum.Ongoing:
      return 'success';
    case SalesPlanningTypes.StatusEnum.Finished:
      return 'primary';
    default:
      return 'warning';
  }
};

const getSalesPlanningTypeColor = (
  status: SalesPlanningTypes.TypeEnum,
): ChipProps['color'] => {
  switch (status) {
    case SalesPlanningTypes.TypeEnum.Sales:
      return 'success';
    case SalesPlanningTypes.TypeEnum.Content:
      return 'primary';
    default:
      return 'warning';
  }
};

const getDiscountStatusColor = (
  status: DiscountTypes.ShopifyStatusEnum,
): ChipProps['color'] => {
  switch (status) {
    case DiscountTypes.ShopifyStatusEnum.Active:
      return 'success';
    case DiscountTypes.ShopifyStatusEnum.Scheduled:
      return 'primary';
    default:
      return 'error';
  }
};

const getUserRoleColor = (status: UserTypes.RoleEnum): ChipProps['color'] => {
  switch (status) {
    case UserTypes.RoleEnum.MANAGEMENT:
      return 'success';
    case UserTypes.RoleEnum.MARKETING_MANAGER:
      return 'primary';
    default:
      return 'warning';
  }
};

const getBrandStatusColor = (status: BrandStatusEnum): ChipProps['color'] => {
  switch (status) {
    case BrandStatusEnum.New:
      return 'default';
    case BrandStatusEnum.Approached:
    case BrandStatusEnum['Re-approached']:
      return 'primary';
    case BrandStatusEnum['Collaboration test']:
    case BrandStatusEnum['Collaboration content']:
    case BrandStatusEnum['Collaboration long-term']:
      return 'success';
    case BrandStatusEnum['Product test']:
    case BrandStatusEnum['In negotiation']:
      return 'info';
    case BrandStatusEnum.Waitlist:
    case BrandStatusEnum['On radar']:
      return 'warning';
    case BrandStatusEnum.Parked:
    case BrandStatusEnum['Not interested']:
    case BrandStatusEnum.Blacklist:
      return 'error';
    default:
      return 'default';
  }
};

export {
  getDealStatusColor,
  getNegotiationStatusColor,
  getCampaignStatusColor,
  getDiscountStatusColor,
  getSalesPlanningStatusColor,
  getBrandStatusColor,
  getCampaignTypeColor,
  getSalesPlanningTypeColor,
  getUserRoleColor,
};
