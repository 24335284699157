import { Control, UseFormWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { NotificationsActiveOutlined } from '@mui/icons-material';
import { FormCard, FormInput } from 'components';
import { DealTypes } from 'api';

interface Props {
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  control: Control<DealTypes.ItemCreateParams> | undefined;
}

const ReminderStoryInsightsBlock = (props: Props): JSX.Element | null => {
  const { control, watch } = props;

  const reminderDate = watch('reminder_date');

  return (
    <FormCard
      title="Reminder story influencer insights"
      avatar={<NotificationsActiveOutlined />}
      CardHeaderProps={{
        subheader: !reminderDate
          ? 'Fields disabled because reminder date is not set'
          : undefined,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="rs_actual_reach"
            inputType="number"
            label="Actual reach"
            disabled={!reminderDate}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="rs_impressions"
            inputType="number"
            label="Impressions"
            disabled={!reminderDate}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="rs_clicks"
            inputType="number"
            label="Clicks"
            disabled={!reminderDate}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ReminderStoryInsightsBlock;
